import React from "react";
import Modal from "react-modal";
import styled, { createGlobalStyle } from "styled-components";
import { OutlineButton } from "../../../ui/Button";
import Icon from "../../../ui/Icon";
import { JustPotLogo, PotLogo } from "../../../ui/Logo";
import { theme } from "../../../ui/theme";
import { Wrapper } from "../components";
import { Header } from "./components";

type Variant = "blogger" | "main";

interface Props {
  transparent?: boolean;
  variant?: Variant;
}

export const SmallPotLogo = styled(PotLogo)`
  height: 39.85px;
  width: 115px;
`;

export const PotLogoShrinker = styled(PotLogo)`
  height: 50px;
  width: 144px;
  @media only screen and (max-width: 425px) {
    height: 39.85px;
    width: 115px;
  }
`;

const Line = styled("div")`
  width: 100%;
  height: 1px;
  background-color: #f3f2f0;
`;

const DesktopWrapper = styled("div")`
  @media only screen and (max-width: 540px) {
    display: none;
  }
`;

const MobileWrapper = styled("div")`
  @media only screen and (min-width: 540px) {
    display: none;
  }
`;

const HideBody = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const linkStyles = {
  padding: 20,
  fontFamily: theme.fonts.ui,
  fontSize: "1.25rem",
  color: "#666",
  letterSpacing: ".026em",
  textTransform: "uppercase",
} as React.CSSProperties;

export const DesktopLinks = ({ variant }: { variant: Variant }) => (
  <DesktopWrapper>
    {/* {variant === "main" && (
      <a style={{ color: "#808080", marginRight: "3em" }} href={"/blogger"}>
        for Bloggers
      </a>
    )} */}
    <a
      style={{ color: "#808080", marginRight: "3em", fontSize: "0.875rem" }}
      href={"/contact"}
    >
      Contact
    </a>
    {variant === "main" && (
      <>
        <a
          style={{
            color: "#531410",
            marginRight: "1em",
            fontSize: "0.875rem",
          }}
          href={"/login"}
        >
          Sign in
        </a>
        <a href="/register">
          <OutlineButton
            style={{
              textTransform: "none",
              fontFamily: theme.fonts.secondary,
              letterSpacing: 0,
            }}
          >
            Sign up
          </OutlineButton>
        </a>
      </>
    )}
    {variant === "blogger" && (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:julie@mysaffronapp.com"
      >
        <OutlineButton color="black">Let’s talk!</OutlineButton>
      </a>
    )}
  </DesktopWrapper>
);

export class MobileLinks extends React.Component<
  { variant: Variant },
  { open: boolean }
> {
  state = { open: false };

  close = () => this.setState({ open: false });

  render() {
    const { variant } = this.props;
    const { open } = this.state;

    return (
      <MobileWrapper>
        <Icon
          name="menu"
          height="30px"
          width="30px"
          fill="#333"
          onClick={() => this.setState({ open: true })}
        />
        <Modal
          isOpen={open}
          onRequestClose={this.close}
          style={{
            content: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              padding: "30px",
              paddingTop: "20px",
              backgroundColor: "#e1dfce",
              border: "none",
              borderRadius: 0,
            },
            overlay: {
              zIndex: 6,
            },
          }}
        >
          {open && <HideBody />}
          <div style={{ position: "absolute", top: 25, right: 25 }}>
            <Icon
              name="x"
              height="2em"
              width="2em"
              fill="#b3b3b3"
              onClick={this.close}
            />
          </div>
          <div
            style={{
              marginTop: 120,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <JustPotLogo width="114.5px" height="132.5px" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 50,
                width: 180,
                alignItems: "center",
              }}
            >
              {/* {variant === "main" && (
                <>
                  <Line />
                  <a style={linkStyles} href={"/blogger"}>
                    for Bloggers
                  </a>
                </>
              )} */}
              <Line />
              <a style={linkStyles} href={"/contact"}>
                Contact
              </a>
              <Line />
              {variant === "main" && (
                <>
                  <a style={linkStyles} href={"/login"}>
                    Sign in
                  </a>
                  <Line />
                  <a style={linkStyles} href="/register">
                    Sign Up
                  </a>
                </>
              )}
              {variant === "blogger" && (
                <a
                  style={linkStyles}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:julie@mysaffronapp.com"
                >
                  Let’s talk!
                </a>
              )}
              <Line />
            </div>
          </div>
        </Modal>
      </MobileWrapper>
    );
  }
}

export const StickyHeader = ({ transparent, variant = "main" }: Props) => {
  return (
    <Header variant={transparent ? "transparent" : variant}>
      <Wrapper
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <a href="/">
          <PotLogoShrinker />
        </a>

        <div style={{ marginLeft: "auto" }}>
          <DesktopLinks variant={variant} />
          <MobileLinks variant={variant} />
        </div>
      </Wrapper>
    </Header>
  );
};
