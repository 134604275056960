import styled from "styled-components";

import headerBackground from "./header-background.jpg";
import { heroBackgroundSize, HEADER_HEIGHT } from "../Hero/components";

type HProps = {
  variant: "transparent" | "blogger" | "main";
};

export const Header = styled("div")<HProps>`
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  ${p =>
    p.variant === "transparent"
      ? "background-color: rgba(255, 255, 255, .3);"
      : ""}
  ${p =>
    p.variant === "main"
      ? `background: url(${headerBackground}); ${heroBackgroundSize}`
      : ""}
  ${p => (p.variant === "blogger" ? `background-color: #fafafa;` : "")}
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  border-bottom: 3px solid rgba(221, 226, 227, 0.2);
  ${p => (p.variant === "blogger" ? "border-bottom: 3px solid #C2B599;" : "")}
  padding: 0px 1em;
  z-index: 5;
`;
